import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "js-cookie";

function PackScreen() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios
      .get("/api/categories/")
      .then((res) => {
        setCategories(res.data.categories);
      })
      .catch((err) => {
        setCategories([]);
      });
  }, []);

  return (
    <div className="mt-36">
      <div className="px-8 py-4 max-w-screen-xl mx-auto  ">
        <div
          className={`pb-10 pt-10 grid md:grid-cols-2 grid-cols-1 lg:gap-x-6 gap-x-4 lg:gap-y-2 gap-y-1  lg:col-span-3`}
        >
          {categories?.map((cat) => (
            <div
              key={cat._id}
              className="hover:shadow-md rounded-md pb-3 lg:-20 "
            >
              <Link to={`/category/${cat._id}`}>
                <div className="w-full fade-in lg:group-hover:scale-105 group-hover:shadow-me duration-300 rounded-xl overflow-hidden  shadow-me">
                  {process.env.REACT_APP_ENV === "prod" ? (
                    <img
                      alt={cat.name}
                      src={`https://api.diffatours.com${cat.image}`}
                      className=" duration-300 w-full h-52 object-center object-cover "
                    />
                  ) : (
                    <img
                      alt={cat.name}
                      src={`http://127.0.0.1:8080${cat.image}`}
                      className=" duration-300 w-full md:h-56 h-60 object-center object-cover "
                    />
                  )}
                </div>

                <div className="p-2">
                  <h1 className="text-xl py-2 opacity-80 font-bold capitalize">
                    {Cookies.get("i18next") === "ar" && cat.namear
                      ? cat.namear
                      : Cookies.get("i18next") === "fr" && cat.namefr
                      ? cat.namefr
                      : Cookies.get("i18next") === "it" && cat.nameit
                      ? cat.nameit
                      : Cookies.get("i18next") === "nl" && cat.nameln
                      ? cat.nameln
                      : cat.name}
                  </h1>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PackScreen;
